nav.menu {
  width:calc(100%);
  height:70px;
  bottom: 0;
  background-color: $primaryColor;
  color: #ffffff;
  position: static;
  top: 37px;
  z-index:9;

  &.sticky {
    position: sticky;
  }

  ul {
    list-style: none;
    display: flex;
    height: 100%;

    li {
      flex-grow: 1;
      text-align: center;
      align-content: center;
      align-self: center;
    }
  }
}