@font-face {
  font-family: 'copperplategothic';
  src: url('../fonts/copperplate_gothic_bold-webfont.woff2') format('woff2'),
  url('../fonts/copperplate_gothic_bold-webfont.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'copperplategothic';
  src: url('../fonts/copperplate_gothic_regular-webfont.woff2') format('woff2'),
  url('../fonts/copperplate_gothic_regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

h1, h2, h3 {
  font-family: "copperplategothic", serif;
  font-weight: normal;

  > span {
    font-weight: normal;
  }

  font-size: 2.1em;
}

h3 {
  font-size: 1.6em;
}

p {
  font-size: 1.2em;
  font-family: "copperplategothic", serif;
  font-weight: 300;
}

ul {
  li {
    font-size: 1.1em;
    font-family: "copperplategothic", serif;
    font-weight: 300;
  }
}